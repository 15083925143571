import * as React from "react"
import Layout from "../../components/layout"

const RequestADemoThanks = () => {
  return (
    <Layout>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center lg:w-2/3 mx-auto mb-12 bg-white rounded-md p-5">
            <h1 className="sm:text-3xl text-2xl font-bold title-font mb-4 text-gray-900">
              Thank You for requesting a demo.
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Your request for a product demo has been successfully submitted.
              We will follow up with you shortly and schedule a session.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RequestADemoThanks
